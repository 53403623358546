import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url-multi-param"
export default class extends Controller {
  static targets = [ 'field' ]

  change() {
    let url = new URL(window.location.href)

    this.fieldTargets.forEach((field) => {
      if (field.value == '' || field.value == null) {
        url.searchParams.delete(field.id)
      } else {
        url.searchParams.set(field.id, field.value)
        url.searchParams.delete('page') // Don't send page number to backend so that we start on page 1.
      }
    })

    window.location = url
  }
}
